import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCR0K7sI83pzh7inL4p8lK9HeAUq8R86cg',
  authDomain: 'apollo-scooters-4fa02.firebaseapp.com',
  databaseURL: 'https://apollo-scooters-4fa02-default-rtdb.firebaseio.com',
  projectId: 'apollo-scooters-4fa02',
  storageBucket: 'apollo-scooters-4fa02.appspot.com',
  messagingSenderId: '735869666705',
  appId: '1:735869666705:web:106d335b338681ac218630',
  measurementId: 'G-L5ZEJC1X8Y',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export default db

<template>
  <div class="">
    <b-card title="Users logs">
      <b-row>
        <b-col cols="12">
          <validation-observer ref="newRoleForm">
            <b-form @submit.prevent="getFirebaseLogs">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="User email"
                  rules="required"
                >
                  <b-form-input
                    v-model="userEmail"
                    placeholder="User email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <b-button
            variant="primary"
            type="submit"
            class="float-right"
            @click.prevent="getFirebaseLogs"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="logs.length > 0 || clientSearch">
      <b-col>
        <new-table
          :export-file-name="exportFileName"
          :columns="columns"
          :items="logs"
          table-name="users-logs-list"
        />
      </b-col>
    </b-card>
    <b-card v-else-if="logs.length <= 0 && !clientSearch && !firstload">
      <b-col>
        <div>
          Sorry no results for your query
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { collection, getDocs } from 'firebase/firestore'
import {
  BCard, BButton, BCol, BRow, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import ATable from '@/views/Table.vue'
import firestoredb from '@/firebase.conf'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'

export default {
  components: {
    // ATable,
    BCard,
    BButton,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    NewTable,
  },
  data() {
    return {
      moment: Moment,
      exportFileName: `users-logs-${Moment(new Date()).format('YYYY-MM-DD')}`,
      clientSearch: false,
      logs: [],
      original_logs: [],
      firstload: true,
      filterOn: ['email', 'date', 'res', 'screen', 'value'],
      logsFields: [
        { key: 'email', label: 'Email', sortable: true },
        { key: 'date', label: 'Date', sortable: true },
        { key: 'res', label: 'Resource', sortable: true },
        { key: 'screen', label: 'Screen', sortable: true },
        { key: 'value', label: 'Value', sortable: true },
      ],

      columns: [
        {
          label: 'Email',
          data: 'email',
        },
        {
          label: 'Date',
          data: 'date',
        },
        {
          label: 'Resource',
          data: 'res',
        },

        {
          label: 'Screen',
          data: 'screen',
        },

        {
          label: 'Value',
          data: 'value',
        },

      ],

      userEmail: '',
    }
  },
  methods: {
    getFirebaseLogs() {
      this.$refs.newRoleForm.validate()
      this.logs = []
      getDocs(collection(firestoredb, `android/user-log/${this.userEmail}`)).then(snapshot => {
        snapshot.docs.forEach(doc => {
          this.logs.push({ ...doc.data() })
        })
        this.clientSearch = false
        this.firstload = false
      })
      this.original_logs = this.logs
    },
    searchOnTable(val) {
      this.logs = this.original_logs
      if (val) {
        const raws = []
        const $values = this.findInValues(this.logs, val)
        if ($values.length > 0) raws.push(...$values)
        this.logs = raws
        this.clientSearch = true
      }
    },
    findInValues(arr, value) {
      return arr.filter(o => Object.entries(o).some(entry => String(entry[1]).toLowerCase().includes(String(value).toLowerCase())))
    },
  },
}
</script>

<style>

</style>
